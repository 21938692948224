import React from 'react'
import styled from 'styled-components'

import Logo from './Logo'

import { SITE } from '../globals'

const HeaderStyled = styled.header`
  padding: 1.4rem 2rem;
  background: #fff;
  border-bottom: 1px solid #dddddd;
  display: flex;
  flex: row nowrap;
  align-items: center;
  justify-content: space-between;
`
const Title = styled.div`
  font-size: 2rem;
`

const Header = () => (
  <HeaderStyled>
    <Logo
      style={{
        height: SITE.logo.headerHeight,
      }}
    />
    <Title>Gestión de incidencias</Title>
  </HeaderStyled>
)

export default Header
