import React, { Fragment, useReducer, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import MediaQuery from 'react-responsive'
import { Table, Pagination, Input, Icon, Grid, Label } from 'semantic-ui-react'
import { DebounceInput } from 'react-debounce-input'

import reducer from './reducer';

import {
    REQUEST_STARTED,
    REQUEST_SUCCEEDED,
    REQUEST_FAILED,
    SET_SEARCH,
    PAGINATION_CHANGE,
    SORT_CHANGE,
    DATE_BOUNDS_CHANGE
} from './actions';

/**
 * Takes an object and show its content in a table. All the pagination,
 * order, row/items selection and search is handled by the backend (with API requests).
 *
 * @function TableBackEnd functional component
 */
const TableBackEndComponent = ({
    url,
    apiRequestOptions,
    header,
    hasPagination,
    pageUrlVar,
    pageSizeUrlVar,
    hasSort,
    sortColUrlVar,
    sortDirUrlVar,
    hasSearch,
    hasDateFilters,
    searchUrlVar,
    totalPagesExtraction,
    handleApiRequestError,
    defaultPage,
    defaultPageSize,
    defaultSearch,
    defaultSortCol,
    defaultSortDir,
    showIdCol,
    hasLoader,
    dataExtraction,
    dataListHasChanged,
    ...rest
}) => {

    const initialState = {
        status: 'loading',
        dataRaw: null,
        activePage: defaultPage,
        pageSize: defaultPageSize,
        totalPages: 1,
        search: defaultSearch,
        sortColumn: defaultSortCol,
        sortDirection: defaultSortDir,
        selectedRowId: null,
        selectedItemsList: {},
        allItemsPageSelected: {},
        detectionDateStarts: '',
        detectionDateEnds: '',
        dateBoundsError: false
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    const {
        activePage,
        pageSize,
        sortColumn,
        sortDirection,
        search,
        detectionDateStarts,
        detectionDateEnds
    } = state;

    //memorize api calculation so that, api request won't be made every render if 
    //params haven't changed
    const apiEndPoint = useMemo(() => {
        let queryParams = url.slice(-1) !== '&' ? '?' : '';

        if (hasPagination) queryParams += `${pageUrlVar}=${activePage}&${pageSizeUrlVar}=${pageSize}&`;
        if (hasSort) queryParams += `${sortColUrlVar}=${sortColumn}&${sortDirUrlVar}=${sortDirection}&`;
        if (hasSearch) queryParams += searchUrlVar.map(v => `${v}=${search}`).join('&');
        if (hasDateFilters) {
            if (detectionDateStarts) queryParams += `&detection_date_gte=${detectionDateStarts}`;
            if (detectionDateEnds) queryParams += `&detection_date_lt=${detectionDateEnds}`;
        }

        return `${url}${queryParams}`;
    }, [
        url, hasPagination, pageUrlVar, activePage, pageSizeUrlVar, pageSize,
        hasSort, sortColUrlVar, sortColumn, sortDirUrlVar, sortDirection, hasSearch, searchUrlVar,
        hasDateFilters, detectionDateStarts, detectionDateEnds, search
    ]);

    //memorize getData function to change only if the request params had changed
    const getData = useCallback(() => {
        dispatch({ type: REQUEST_STARTED });

        axios({
            method: 'get',
            url: apiEndPoint,
            ...apiRequestOptions,
        })
            .then(response => {
                dispatch({
                    type: REQUEST_SUCCEEDED,
                    payload: {
                        data: response.data,
                        totalPages: hasPagination ? totalPagesExtraction(response.data) : 1,
                    }
                });
            })
            .catch(error => {
                console.error(error);
                dispatch({ type: REQUEST_FAILED });
                handleApiRequestError(error)
            })
    }, [
        apiEndPoint, apiRequestOptions, handleApiRequestError,
        hasPagination, totalPagesExtraction
    ]);

    //use effect that will be triggered every time some of the request params 
    //changes
    useEffect(() => {
        getData();
    }, [getData, dataListHasChanged]);

    // When the user clicks on a page button.
    const handlePaginationChange = (e, { activePage }) => dispatch({
        type: PAGINATION_CHANGE,
        payload: { activePage }
    })

    // When the user clicks on a column header (and the table and
    // clicked column have sorting enabled).
    const handleSort = (column) => dispatch({
        type: SORT_CHANGE,
        payload: { sortColumn: column }
    });

    // When the user write something in the search input.
    const handleSearch = (e) => dispatch({
        type: SET_SEARCH,
        payload: { searchTerm: e.target.value }
    });

    const handleDateChange = ({ target }) => dispatch({
        type: DATE_BOUNDS_CHANGE,
        payload: {
            inputName: target.name,
            value: target.value
        }
    });

    const headerKeys = Object.keys(header);
    const nCols = headerKeys.length - (showIdCol ? 0 : 1);
    const processedData = state.dataRaw ? dataExtraction(state.dataRaw) : [];

    return (
        <MediaQuery minWidth={500}>
            {matches => (
                <Table sortable={hasSort}{...rest} >
                    {hasSearch && (
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={nCols}>
                                    <DebounceInput
                                        autoComplete="off"
                                        id="tbe-search"
                                        fluid
                                        element={Input}
                                        minLength={2}
                                        debounceTimeout={300}
                                        placeholder="Buscar..."
                                        icon="search"
                                        name="search"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                    )}
                    {hasDateFilters && (
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={nCols}>
                                    <Grid columns={2} divided>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <DebounceInput
                                                    autoComplete="off"
                                                    id="tbe-detection-date-starts"
                                                    element={Input}
                                                    type="date"
                                                    fluid
                                                    debounceTimeout={100}
                                                    placeholder="Desde"
                                                    icon="calendar"
                                                    name="detectionDateStarts"
                                                    value={state.detectionDateStarts}
                                                    onChange={handleDateChange}
                                                />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <DebounceInput
                                                    autoComplete="off"
                                                    id="tbe-detection-date-ends"
                                                    element={Input}
                                                    type="date"
                                                    fluid
                                                    debounceTimeout={100}
                                                    placeholder="Hasta"
                                                    icon="calendar"
                                                    name="detectionDateEnds"
                                                    value={state.detectionDateEnds}
                                                    onChange={handleDateChange}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    {state.dateBoundsError && (
                                        <Label prompt>
                                            La fecha fin no puede ser menor a la fecha de inicio
                                        </Label>
                                    )}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                    )}
                    {header && headerKeys.length > 0 && (
                        <Table.Header>
                            <Table.Row>
                                <Fragment>
                                    {headerKeys.map(colId => {
                                        if (!showIdCol && colId === 'id') return null

                                        const { text, unsortable, ...hrest } = header[colId]
                                        return (
                                            <Table.HeaderCell
                                                key={colId}
                                                sorted={
                                                    hasSort && !unsortable && sortColumn === colId
                                                        ? `${sortDirection}ending`
                                                        : undefined
                                                }
                                                onClick={
                                                    hasSort && !unsortable
                                                        ? () => handleSort(colId)
                                                        : undefined
                                                }
                                                {...hrest}
                                            >
                                                {text}
                                            </Table.HeaderCell>
                                        )
                                    })}
                                </Fragment>
                            </Table.Row>
                        </Table.Header>
                    )}
                    <Table.Body>
                        {state.status === 'error' ? (
                            <Table.Row>
                                <Table.Cell
                                    colSpan={nCols}
                                    textAlign="center"
                                    style={{
                                        padding: '2rem',
                                        color: 'red',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <div>ERROR: No se pueden cargar los datos</div>
                                </Table.Cell>
                            </Table.Row>
                        ) : (
                                processedData.map(dataRow => (
                                    <Table.Row
                                        key={dataRow.id}
                                        className="tbe-row"
                                    >
                                        <Fragment>
                                            {Object.keys(dataRow).map(dataColId => {
                                                if (!showIdCol && dataColId === 'id') return null

                                                return (
                                                    <Table.Cell
                                                        key={dataRow.id + dataColId}
                                                        className="tbe-cell"
                                                    >
                                                        {dataRow[dataColId]}
                                                    </Table.Cell>
                                                )
                                            })}
                                        </Fragment>
                                    </Table.Row>
                                ))
                            )}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={nCols}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexFlow: matches
                                            ? 'row nowrap'
                                            : 'column-reverse nowrap',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '2.2rem',
                                            marginTop: matches ? undefined : '1.2rem',
                                        }}
                                    >
                                        {hasLoader && state.status === 'loading' && (
                                            <div>
                                                <Icon
                                                    loading
                                                    size="big"
                                                    name="spinner"
                                                    color="blue"
                                                />
                                                {'  '}
                      Cargando datos...
                                            </div>
                                        )}
                                    </div>
                                    {hasPagination && state.totalPages > 1 && (
                                        <Pagination
                                            floated="right"
                                            activePage={activePage}
                                            totalPages={state.totalPages}
                                            onPageChange={handlePaginationChange}
                                            {...(!matches
                                                ? {
                                                    size: 'mini',
                                                    boundaryRange: 0,
                                                    siblingRange: 0,
                                                }
                                                : {})}
                                        />
                                    )}
                                </div>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            )}
        </MediaQuery>
    );
}

TableBackEndComponent.propTypes = {
    url: PropTypes.string.isRequired,
    apiRequestOptions: PropTypes.object,
    header: PropTypes.object,
    dataExtraction: PropTypes.func,
    handleApiRequestError: PropTypes.func,
    showIdCol: PropTypes.bool,
    hasLoader: PropTypes.bool,
    hasPagination: PropTypes.bool,
    pageUrlVar: PropTypes.string,
    pageSizeUrlVar: PropTypes.string,
    defaultPage: PropTypes.number,
    defaultPageSize: PropTypes.number,
    totalPagesExtraction: PropTypes.func,
    hasSearch: PropTypes.bool,
    searchUrlVar: PropTypes.array,
    defaultSearch: PropTypes.string,
    hasSort: PropTypes.bool,
    sortColUrlVar: PropTypes.string,
    sortDirUrlVar: PropTypes.string,
    defaultSortCol: PropTypes.string,
    defaultSortDir: PropTypes.string,
    dataListHasChanged: PropTypes.bool,
    hasDateFilters: PropTypes.bool,
}

TableBackEndComponent.defaultProps = {
    apiRequestOptions: {},
    header: {},
    dataExtraction: data => data,
    handleApiRequestError: () => null,
    showIdCol: false,
    hasLoader: true,
    hasPagination: true,
    pageUrlVar: 'page',
    pageSizeUrlVar: 'limit',
    defaultPage: 1,
    defaultPageSize: 25,
    totalPagesExtraction: data => 1,
    hasSearch: false,
    searchUrlVar: ['search'],
    defaultSearch: '',
    hasSort: true,
    sortColUrlVar: 'sort',
    sortDirUrlVar: 'direction',
    defaultSortCol: '',
    defaultSortDir: 'desc',
    dataListHasChanged: false,
    hasDateFilters: false
}

export const TableBackEnd = TableBackEndComponent;