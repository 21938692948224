export const formatDatetime = (dtSource, hasTime = true) => {
  if (!dtSource) return null
  const date = new Date(dtSource)

  let options = {}
  options.timeZone = 'Europe/Madrid'
  options.year = 'numeric'
  options.month = '2-digit'
  options.day = '2-digit'
  if (hasTime) {
    options.hour = '2-digit'
    options.minute = '2-digit'
  }

  return date.toLocaleString('es-ES', options)
}

export const formatNumber = (number = 0) => {
  return new Intl.NumberFormat('es-ES').format(number)
}

export const formatCurrency = (amount = 0) => {
  return new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount)
}

export const formatPhone = (phone, separator = ' ') => {
  return phone.replace(/\B(?=(\d{3})+(?!\d))/g, separator)
}

export const flattenObject = (obj, prefix = '') =>
  Object.keys(obj).reduce((t, k) => {
    const item = obj[k]
    const element =
      item && typeof item === 'object'
        ? flattenObject(item, `${prefix}${k}/`)
        : [`${prefix}${k}: ${item}`]
    return [...t, ...element]
  }, [])

export const toLocaleISOString = dt => {
  const dateFields = dt
    .toLocaleDateString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .split('/')
  const time = dt.toLocaleTimeString('en-US', { hour12: false })
  return `${dateFields[2]}-${dateFields[1]}-${dateFields[0]}T${time}`
}
