import React, { PureComponent, createContext } from 'react'
import axios from 'axios'

import { APIBASEURL } from '../../globals'

const ApiContext = createContext()

export class ApiProvider extends PureComponent {
  state = {}

  apiRequest = (type, endpoint, data = {}) => {
    return new Promise((resolve, reject) => {
      axios({
        method: type,
        url: `${APIBASEURL}${endpoint}`,
        responseType: 'json',
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data,
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.error(error)
          reject(error)
        })
    })
  }

  getRequest = endpoint => this.apiRequest('get', endpoint)

  postRequest = (endpoint, data) => this.apiRequest('post', endpoint, data)

  putRequest = (endpoint, data) => this.apiRequest('put', endpoint, data)

  deleteRequest = endpoint => this.apiRequest('delete', endpoint)

  render() {
    const api = {
      ...this.state,
      apiRequest: this.apiRequest,
      getRequest: this.getRequest,
      postRequest: this.postRequest,
      putRequest: this.putRequest,
      deleteRequest: this.deleteRequest,
    }
    return (
      <ApiContext.Provider value={api}>
        {this.props.children}
      </ApiContext.Provider>
    )
  }
}

export function withApiContext(Component) {
  return function WithApiContextComponent(props) {
    return (
      <ApiContext.Consumer>
        {api => <Component {...props} api={api} />}
      </ApiContext.Consumer>
    )
  }
}
