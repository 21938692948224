import React, { Component, Fragment } from "react";
import { Button, Segment } from "semantic-ui-react";
import styled from "styled-components";

import GlobalStyle from "../components/styled/GlobalStyle";
import Messages from "../components/Messages";
import Header from "../components/Header";
import IncidenceForm from "../components/Incidence/Form";

const ButtonBar = styled.div`
  text-align: right;
`;

class Root extends Component {
  state = { key: new Date().getTime(), createNewIncidence: true };

  handleNewIncidence = (status) =>
    this.setState({ createNewIncidence: status });

  handleSubmit = () =>
    this.setState({ key: new Date().getTime(), createNewIncidence: false });

  handleCancel = () => this.setState({ createNewIncidence: false });

  render() {
    const { createNewIncidence } = this.state;
    return (
      <Fragment>
        <GlobalStyle />
        <Header />
        <main>
          {createNewIncidence ? (
            <Segment padded>
              <h3 onClick={this.handleCancel}>Nueva incidencia</h3>
              <IncidenceForm
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
              />
            </Segment>
          ) : (
            <ButtonBar>
              <Button
                className="add-incidencia-button"
                content="Crear nueva incidencia"
                icon="plus"
                onClick={() => this.handleNewIncidence(true)}
              />
            </ButtonBar>
          )}
        </main>
        <Messages />
      </Fragment>
    );
  }
}

export default Root;
