import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Form, Segment, Button } from 'semantic-ui-react'

export class FilesUploader extends PureComponent {
  static propTypes = {
    defaultFiles: PropTypes.array,
    errors: PropTypes.object,
    handleFileChange: PropTypes.func,
  }
  static defaultProps = {
    defaultFiles: [],
    errors: {},
    handleFileChange: () => null,
  }

  state = {
    status: 'waiting',
    files: this.props.defaultFiles,
  }

  /* LIFECYCLE HOOKS */

  componentDidUpdate(prevProps, prevState) {
    if (prevState.files !== this.state.files)
      this.props.handleFileChange(this.state.files)
  }

  /* HANDLERS */

  // When select a file to upload.
  handleFileSelection = (e, values) => {
    const filesData = e.target.files
    this.setState({ status: 'loading' })

    for (let i = 0, l = filesData.length; i < l; i++) {
      this.readFile(filesData[i], 'urldata')
        .then(fileContent => {
          this.setState(prevState => ({
            status: 'waiting',
            files: [
              ...prevState.files,
              {
                name: '',
                fileName: filesData[i].name,
                data: fileContent,
              },
            ],
          }))
        })
        .catch(error => {
          console.error(error)
          this.setState({ status: 'error' })
        })
    }
  }

  handleFileNameChange = (e, { value, index }) => {
    this.setState(prevState => ({
      files: [
        ...prevState.files.slice(0, index),
        { ...prevState.files[index], name: value },
        ...prevState.files.slice(index + 1),
      ],
    }))
  }

  handleRemoveFile = index => {
    this.setState(prevState => ({
      files: prevState.files.filter((v, i) => i !== index),
    }))
  }

  /* FUNCTIONS */

  // Reads the data in a file and return it using promises.
  readFile = (file, output = 'text', encoding = 'UTF-8') => {
    return new Promise((resolve, reject) => {
      if (!file) reject(new Error('No se encuentra el fichero'))

      var reader = new FileReader()
      reader.onload = fileContent => {
        resolve(fileContent.target.result)
      }
      reader.onerror = e => {
        reject(e.target.error)
      }
      if (output === 'text') reader.readAsText(file, encoding)
      else if (output === 'urldata') reader.readAsDataURL(file)
      else if (output === 'binary') reader.readAsBinaryString(file)
      else if (output === 'arraybuffer') reader.readAsArrayBuffer(file)
      else reject(new Error('Formato de salida de fichero desconocido'))
    })
  }

  render() {
    const { errors } = this.props
    const { files } = this.state

    return (
      <Fragment>
        <Form.Input
          type="file"
          multiple
          label="Documentos"
          name="file"
          onChange={this.handleFileSelection}
        />
        {files.length > 0 &&
          files.map((v, i) => (
            <Segment
              key={v.fileName}
              style={{
                padding: '0.6rem 0.6rem 0.6rem 1rem',
                margin: '0 0 0.8rem 2rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <div style={{ flex: '1 1 auto' }}>
                  <Form.Input
                    index={i}
                    fluid
                    label="Nombre del documento"
                    name="name"
                    value={v.name}
                    onChange={this.handleFileNameChange}
                    error={errors[`files[${i}].name`] !== undefined}
                  />
                </div>
                <div
                  style={{
                    width: '40%',
                    fontStyle: 'italic',
                    margin: '0.6rem 1.6rem',
                  }}
                >
                  {v.fileName}
                </div>
                <Button icon="trash" onClick={() => this.handleRemoveFile(i)} />
              </div>
              {errors[`files[${i}].name`] !== undefined && (
                <div style={{ color: 'red', margin: '0.6rem 0 00 0' }}>
                  {errors[`files[${i}].name`].map((v, i) => (
                    <div key={`${i}-${v}`}>- {v}</div>
                  ))}
                </div>
              )}
            </Segment>
          ))}
      </Fragment>
    )
  }
}
