import React from 'react'
import ReactDOM from 'react-dom'

import { ApiProvider, MessagesProvider } from './components/Context'
import Root from './screens/Root'

import 'semantic-ui-css/semantic.min.css'

ReactDOM.render(
  <ApiProvider>
    <MessagesProvider>
      <Root />
    </MessagesProvider>
  </ApiProvider>,
  document.getElementById('root')
)
