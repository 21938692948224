import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Form } from "semantic-ui-react";

import { withMessagesContext, withApiContext } from "../Context";
import { DataLoader, DropdownDbList, Space, FormInputErrors } from "../UI";

import { setFormValidationLanguage } from "../../formvalidation";
import { flattenObject } from "../../utils";
import { APIBASEURL } from "../../globals";

class IncidenceForm extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
  };
  static defaultProps = {
    onSubmit: () => null,
    onCancel: () => null,
  };

  state = {
    status: "loading",
    errors: {},
    formData: {
      id: "",
      areaId: "",
      place: "",
      description: "",
      reporter: "",
      email: "",
    },
  };

  componentDidMount() {
    this.schema = this.setFormValidationSchema();

    // if (this.props.id) this.getData(this.props.id)
    // else this.setState({ status: 'loaded' })
    this.setState({ status: "loaded" });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.formData !== prevState.formData &&
      this.state.status === "submitted"
    ) {
      this.schema
        .validate(this.state.formData, { abortEarly: false })
        .then((value) => {
          this.setState({
            errors: {},
          });
        })
        .catch((error) => {
          this.setState({
            errors: this.transformErrorsArrayToObject(error.inner),
          });
        });
    }
  }

  handleFieldChange = (e, { name, value, checked, options }) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: checked !== undefined ? checked : value,
      },
    }));
  };

  handleSubmit = () => {
    this.schema
      .validate(this.state.formData, { abortEarly: false })
      .then((value) => {
        const data = {
          ...(value.id ? { id: value.id } : {}),
          ...(value.areaId ? { area_id: value.areaId } : {}),
          place: value.place,
          description: value.description,
          reporter: value.reporter,
          email: value.email,
        };
        this.postPutData(data);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          status: "submitted",
          errors: this.transformErrorsArrayToObject(error.inner),
        });
      });
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  setFormValidationSchema = () => {
    setFormValidationLanguage("ES");
    return yup.object().shape({
      id: yup.string(),
      areaId: yup.string(),
      place: yup.string(),
      description: yup.string().required(),
      reporter: yup.string().required(),
      email: yup.string().email(),
    });
  };

  transformErrorsArrayToObject = (errorArray) =>
    errorArray.reduce((t, v) => {
      t[v.path] = t[v.path] ? [...t[v.path], v.message] : [v.message];
      return t;
    }, {});

  getData = (id) => {
    const { getRequest } = this.props.api;
    const url = `/public/incidences/${id}`;

    getRequest(url).then((response) => {
      const itemData = response.data;
      this.setState({
        status: "submitted",
        formData: {
          id: itemData.id,
          areaId: itemData.area_id ? itemData.area_id : "",
          place: itemData.place,
          description: itemData.description,
          reporter: itemData.reporter,
          email: itemData.email,
        },
      });
    });
  };

  postPutData = (itemData) => {
    const {
      api: { apiRequest },
      globalMessages: { setMessages },
    } = this.props;
    const { id } = itemData;
    const url = `/public/incidences${id ? "/" + id : ""}`;

    this.setState({ status: "submitting" });
    apiRequest(id ? "PUT" : "POST", url, itemData)
      .then((response) => {
        if (response.data.success) {
          this.setState({ status: "exit" });
          this.props.onSubmit();
        } else {
          this.setState({ status: "submitted" });
          setMessages(flattenObject(response.data.errors), "error");
        }
      })
      .catch((error) => {
        this.setState({ status: "submitted" });
      });
  };

  render() {
    if (this.state.status === "loading") return <DataLoader />;

    const {
      status,
      errors,
      formData: { areaId, place, description, reporter, email },
    } = this.state;

    return (
      <Form>
        <Form.Input
          label="Lugar"
          name="place"
          value={place}
          onChange={this.handleFieldChange}
          error={errors.place !== undefined}
        />
        <FormInputErrors errors={errors.place} />
        <Form.Input
          label="Descripción de la incidencia (*)"
          name="description"
          value={description}
          onChange={this.handleFieldChange}
          error={errors.description !== undefined}
        />
        <FormInputErrors errors={errors.description} />
        <Form.Group widths="equal">
          <DropdownDbList
            url={`${APIBASEURL}/public/areas/list`}
            fluid
            selection
            search
            label="Área"
            name="areaId"
            value={areaId}
            onChange={this.handleFieldChange}
            error={errors.areaId !== undefined}
          />
          <Form.Input
            label="Abierta por (*)"
            name="reporter"
            value={reporter}
            onChange={this.handleFieldChange}
            error={errors.reporter !== undefined}
          />
          <Form.Input
            label="Email de contacto"
            name="email"
            value={email}
            onChange={this.handleFieldChange}
            error={errors.email !== undefined}
          />
        </Form.Group>
        <FormInputErrors errors={errors.reporter} />
        <FormInputErrors errors={errors.email} />
        <Space height="1rem" />
        <Form.Group
          style={{
            textAlign: "flex",
            flexFlow: "row-reverse nowrap",
          }}
        >
          <Form.Button
            type="submit"
            content="Guardar"
            loading={status === "submitting"}
            onClick={status !== "submitting" ? this.handleSubmit : undefined}
          />
        </Form.Group>
      </Form>
    );
  }
}

export default withMessagesContext(withApiContext(IncidenceForm));
