import { setLocale } from 'yup'
import printValue from 'yup/lib/util/printValue'

export const setFormValidationLanguage = lang => {
  /* eslint-disable no-template-curly-in-string */
  const messages = {
    es: {
      mixed: {
        default: '${path} es inválido',
        required: 'Campo obligatorio', //'${path} es un campo obligatorio',
        oneOf: 'Tiene que ser uno de los siguiente valores: ${values}',
        notOneOf: 'No puede ser uno de los siguiente valores: ${values}',
        notType: ({ path, type, value, originalValue }) => {
          let isCast = originalValue != null && originalValue !== value
          let msg =
            `Tiene que ser del tipo \`${type}\`, ` +
            `pero el valor final es: \`${printValue(value, true)}\`` +
            (isCast
              ? ` (transformado a partir del valor \`${printValue(
                  originalValue,
                  true
                )}\`).`
              : '.')

          if (value === null) {
            msg += `\n Si se pretende que "null" sea un valor vacío asegurarse de marcar el schema como \`.nullable()\``
          }

          return msg
        },
      },
      string: {
        length: 'Tiene que tener exactamente ${length} caracteres',
        min: 'Tiene que tener como mínimo ${min} caracteres',
        max: 'Tiene que tener como máximo ${max} caracteres',
        matches: 'Tiene que coincidir con este patrón: "${regex}"',
        email: 'Tiene que ser un email válido',
        url: 'Tiene que ser una URL válida',
        trim: 'Tiene que ser un texto trimmed',
        lowercase: 'Tiene que ser un texto en minúsculas',
        uppercase: 'Tiene que ser un texto en mayúsculas',
      },
      number: {
        min: 'Tiene que ser mayor o igual a ${min}',
        max: 'Tiene que ser menor o igual que ${max}',
        less: 'Tiene que ser menor que ${less}',
        more: 'Tiene que ser mayor que ${more}',
        notEqual: 'Tiene que ser distinto de ${notEqual}',
        positive: 'Tiene que ser un número positivo',
        negative: 'Tiene que ser un número negativo',
        integer: 'Tiene que ser un entero',
      },
      date: {
        min: 'Tiene que ser posterior a ${min}',
        max: 'Tiene que ser anterior a ${max}',
      },
      object: {
        noUnknown:
          'No puede tener claves no especificadas en la definición del objeto',
      },
      array: {
        min: 'Tiene que tener al menor ${min} items',
        max: 'Tiene que tener menos que o exactamente ${max} items',
      },
    },
  }

  setLocale(messages['es'])
}
