import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html, body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px !important;
    margin: 0;
    padding: 0;
    background: #fbfbfb !important;
  }
  h1 {
    font-weight: bold;
    margin: 0;
  }
  a,
  a:hover {
    color: #000;
  }
  button,
  a:hover {
    cursor: pointer;
  }
  a:hover button {
    opacity: 0.85;
  }
  #root {
    height: ${(props) => (props.fullHeight ? "100%" : "default")};
  }
  main {
    margin: 2rem 2rem;
  }
  @media (min-width: 768px) {
    html, body {
      font-size: 13px !important;
    }
  }

  //Table styles 
  table thead > tr {
    > th {
      background-color: rgba(175, 22, 133) !important;
      color: white !important;
    }
  }

  //common colors
  .add-incidencia-button{ 
    background-color: rgba(175, 22, 133) !important;
    color: white !important;
  }
`;
